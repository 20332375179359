<div class="quick-searh-input" [class.expanded]="expanded" (clickOutside)="colapseSearchPanel()">
  <div class="search-input">
    <lib-icon class="search-input__icon" icon="magnifying-glass"></lib-icon>
    <input 
      type="text" 
      [formControl]="searchControl" 
      class="input input--rounded input--placeholder-grey" 
      [placeholder]="'COMMON.start_search_here' | translate"
      (focus)="expandSearchPanel()"
      (keyup.enter)="search.emit(searchControl?.value)">
  
    <lib-icon *ngIf="searchControl?.value" class="search-input__icon clear-icon" icon="x" size="14" (click)="clearSearch()"></lib-icon>
  </div>

  <div class="search-quick-results">
    <div class="divider"></div>

    <div class="tags-quick-results">
      <span class="search-results-label">{{ 'SEARCH.with_tag' | translate }}</span>

      <div class="tags-results">
        <ng-container *ngIf="tags?.length; else tagsPlaceholderTmp">
          <div class="tag-result" *ngFor="let item of tags" (click)="searchByTag.emit(item.id)">
            {{ item.title }}
          </div>
        </ng-container>

        <ng-template #tagsPlaceholderTmp>
          <ng-container *ngIf="tagsLoading; else tagsEmptyTmp">
            <lib-skeleton-loader class="tag-placeholder" *ngFor="let item of loadingPlaceholers"></lib-skeleton-loader>
          </ng-container>
        </ng-template>

        <ng-template #tagsEmptyTmp>
          <span class="search-results-label tags-empty-label">{{ 'SEARCH.no_tags_found' | translate }}</span>
        </ng-template>
      </div>
    </div>

    <ng-container *ngIf="resultTemplate">
      <div class="divider"></div>

      <div class="quick-results-section">
  
        <div class="quick-results-labels" *ngIf="resultsHeaderTemplate">
          <ng-container *ngTemplateOutlet="resultsHeaderTemplate"></ng-container>
        </div>
  
        <div class="quick-results">
          <ng-container *ngIf="data?.length; else dataPlaceholderTmp">
            <div class="search-result" *ngFor="let item of data">
              <ng-container *ngTemplateOutlet="resultTemplate; context: { item: item}"></ng-container>
            </div>
          </ng-container>
        </div>

        <ng-template #dataPlaceholderTmp>
          <ng-container *ngIf="dataLoading; else dataEmptyTmp">
            <lib-skeleton-loader class="data-placeholder" *ngFor="let item of loadingPlaceholers"></lib-skeleton-loader>
          </ng-container>
        </ng-template>

        <ng-template #dataEmptyTmp>
          <span class="search-results-label data-empty-label">{{ 'COMMON.no_results' | translate }}</span>
        </ng-template>

  
      </div>
    </ng-container>

    <div class="divider"></div>

    <div class="search-input-footer">
      <span class="search-results-label">{{ 'SEARCH.all_search_result_for' | translate : { key: searchControl?.value | slice : 0 : 20 } }}</span>

      <span class="search-results-label">{{ 'COMMON.press_enter' | translate }}</span>
    </div>
  </div>
  
</div>