import { Injectable } from '@angular/core';
import { DateRangeFilterOption } from '../models';
import { DateFormatService } from './date-format.service';

@Injectable({
  providedIn: 'root'
})
export class DateRangeFilterService {

  constructor(private readonly dateFormatService: DateFormatService) { }

  public mapRangeOptionToLiterals(range: DateRangeFilterOption): { start: string, end: string } {
    if (!range) {
      return;
    }

    switch (range) {
      case DateRangeFilterOption.TODAY:
        return { start: 'now', end: 'now' };
      case DateRangeFilterOption.LAST_7_DAYS:
        return { start: '-7 day', end: 'now' };
      case DateRangeFilterOption.LAST_30_DAYS:
        return { start: '-30 day', end: 'now' };
      case DateRangeFilterOption.LAST_3_MONTHS:
        return { start: '-3 month', end: 'now' };
      case DateRangeFilterOption.LAST_6_MONTHS:
        return { start: '-6 month', end: 'now' };
      case DateRangeFilterOption.THIS_YEAR:
        return { start: '-1 year', end: 'now' };
      case DateRangeFilterOption.LAST_YEAR:
        return { start: '-2 year', end: '-1 year' };
    }
  }

  public mapRangeOptionToDates(range: DateRangeFilterOption, includeTime = true): { start: string, end: string } {
    if (!range) {
      return;
    }

    const today = new Date();
    const thisYear = new Date(today.getFullYear(), 0, 1);
    const lastYear = new Date(today.getFullYear() - 1, 0, 1);
    const nextYear = new Date(today.getFullYear() + 1, 0, 1);


    let dateRange: { start: Date, end: Date } = null;

    switch (range) {
      case DateRangeFilterOption.TODAY:
        dateRange = { start: new Date(), end: new Date() };
        break;
      case DateRangeFilterOption.LAST_7_DAYS:
        const last7Days = new Date().setDate(today.getDate() - 7);
        dateRange = { start: new Date(last7Days), end: today };
        break;
      case DateRangeFilterOption.LAST_30_DAYS:
        const last30Days = new Date().setDate(today.getDate() - 30);
        dateRange = { start: new Date(last30Days), end: today };
        break;
      case DateRangeFilterOption.LAST_3_MONTHS:
        const last3Month = new Date(today.getFullYear(), today.getMonth() - 3, 1);
        dateRange = { start: new Date(last3Month), end: today };
        break;
      case DateRangeFilterOption.LAST_6_MONTHS:
        const last6Month = new Date(today.getFullYear(), today.getMonth() - 6, 1);
        dateRange = { start: new Date(last6Month), end: today };
        break;
      case DateRangeFilterOption.THIS_YEAR:
        dateRange = { start: new Date(thisYear), end: nextYear };
        break;
      case DateRangeFilterOption.LAST_YEAR:
        dateRange = { start: new Date(lastYear), end: new Date(thisYear) };
        break;
      case DateRangeFilterOption.THIS_WEEK:
        const startOfThisWeek = new Date(today);
        startOfThisWeek.setDate(today.getDate() - (today.getDay() === 0 ? 6 : today.getDay() - 1));
        const endOfThisWeek = new Date(startOfThisWeek);
        endOfThisWeek.setDate(startOfThisWeek.getDate() + 6);
        dateRange = { start: startOfThisWeek, end: endOfThisWeek };
        break;
      case DateRangeFilterOption.NEXT_WEEK:
        const startOfNextWeek = new Date(today);
        startOfNextWeek.setDate(today.getDate() + (today.getDay() === 0 ? 1 : 8 - today.getDay()));
        const endOfNextWeek = new Date(startOfNextWeek);
        endOfNextWeek.setDate(startOfNextWeek.getDate() + 6);
        dateRange = { start: startOfNextWeek, end: endOfNextWeek };
        break;
      case DateRangeFilterOption.THIS_MONTH:
        dateRange = { start: new Date(today.getFullYear(), today.getMonth(), 1), end: new Date(today.getFullYear(), today.getMonth() + 1, 0) };
        break;
      case DateRangeFilterOption.NEXT_MONTH:
        const nextMonth = new Date(today.getFullYear(), today.getMonth() + 1, 1);
        dateRange = { start: nextMonth, end: new Date(today.getFullYear(), today.getMonth() + 2, 0) };
        break;

    }

    return this.formatDataRangeInterval(dateRange?.start, dateRange?.end, includeTime);
  }

  public formatDataRangeInterval(startDate: Date, endDate: Date, includeTime = true): { start: string, end: string } {
    if (!startDate || !endDate) {
      return null;
    }

    startDate.setHours(0, 0, 0, 0);
    endDate.setHours(23, 59, 59, 999);

    return { 
      start: this.dateFormatService.formatDateToAtomISOString(startDate, includeTime), 
      end: this.dateFormatService.formatDateToAtomISOString(endDate, includeTime) 
    };
  }
}
